import Body from "../components/Body";
import SiteHeader from "../components/SiteHeader";
import Line from "../components/Line";
import NavBar from "../components/NavBar";
import Main from "../components/Main";
import TextField from "../components/TextField";
import Footer from "../components/Footer";
import Pers1 from "../components/Files/zeisner.jpg"
import Pers2 from "../components/Files/engels.jpg"


export default function AboutUs() {


    return (
        <Body>
            <section>
                <SiteHeader caption={"Über uns"}/>
                <Line/>
                <NavBar/>
                <Line/>
            </section>
            <Main>
                <TextField>
                    <section className="text_left">
                        <p>
                            <span style={{fontSize: "2em", display: "inline-block", marginBottom: "-0.5em"}}>W</span>ir
                            sind ein kleines innovatives Unternehmen, welches sich auf den Verkauf und Vermietung von
                            Cobots, die Entwicklung von technischen Einsatzmöglichkeiten und deren Umsetzung
                            spezialisiert hat. In enger Zusammenarbeit mit Han´s Robot Shenzen haben wir einen zuverlässigen
                            Partner und sind immer auf dem neuesten Stand der Technik.
                            Durch weitreichende Erfahrung in Bereichen der IT, Elektrotechnik, Automatisierungs- und
                            Sicherheitstechnik sowie im Maschinenbau sind wir ein kompetenter Partner für Sie, um Sie
                            auf dem Weg mit Ihrem neuen CoBot zu begleiten!
                        </p>
                    </section>
                    <section className="profile_container">
                        <div className="profile">
                            <img src={Pers2} alt="Person 2"/>
                            <p>Thomas Engels <br/> (Geschäftsführer)</p>
                        </div>
                        <div className="profile">
                            <img src={Pers1} alt="Person 1"/>
                            <p>Thomas Zeisner <br/> (Geschäftsführer)</p>
                        </div>
                    </section>
                </TextField>
            </Main>
            <Footer/>
        </Body>
    )
}
