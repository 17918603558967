import Header from "../components/Header";
import Line from "../components/Line";
import NavBar from "../components/NavBar";
import Body from "../components/Body";
import Main from "../components/Main";
import Footer from "../components/Footer";
import styled from "styled-components";


export default function DataPrivacy() {


    return (
        <Body>
            <section>
                <Header/>
                <Line/>
                <NavBar/>
                <Line/>
            </section>
            <Main>
                <TextFont>
                    <p>
                        Datenschutzerklärung für die Internetseite
                        www.trobotics.de

                        <h3>I. Name und Anschrift des Verantwortlichen und der Aufsichtsbehörde</h3>

                        <p>Der Verantwortliche im Sinne der Datenschutz-Grundverordnung und anderer nationaler
                            Datenschutzgesetze der Mitgliedsstaaten sowie sonstiger datenschutzrechtlicher Bestimmungen
                            ist
                            die:</p>

                        <p>trobotics</p>
                        <p>Jahnplatz 3</p>
                        <p>53840 Troisdorf</p>

                        <p>Die für den Verantwortlichen zuständige Aufsichtsbehörde ist</p>

                        <p>Landesbeauftragte für Datenschutz und Informationsfreiheit Nordrhein-Westfalen
                            Kavalleriestr. 2-4
                            40213 Düsseldorf
                            Telefon: 0211/38424-0
                            Fax: 0211/38424-999
                            E-Mail:poststelle@ldi.nrw.de</p>

                        <p>https://www.ldi.nrw.de/</p>

                        <h3>II. Begriffe</h3>

                        <p>Unsere Datenschutzerklärung soll einfach lesbar und verständlich sein. Aus diesem Grunde
                            werden
                            zunächst die in dieser Erklärung verwendeten Begriffe erläutert.</p>

                        <p>Wir verwenden in dieser Datenschutzerklärung und auf unserer Internetseite unter anderem die
                            folgenden Begriffe:</p>

                        <p>1. Personenbezogene Daten
                            Personenbezogene Daten sind alle Informationen, die sich auf eine identifizierte oder
                            identifizierbare natürliche Person (im Folgenden „betroffene Person") beziehen. Als
                            identifizierbar wird eine natürliche Person angesehen, die direkt oder indirekt,
                            insbesondere
                            mittels Zuordnung zu einer Kennung wie einem Namen, zu einer Kennnummer, zu Standortdaten,
                            zu
                            einer Online-Kennung oder zu einem oder mehreren besonderen Merkmalen, die Ausdruck der
                            physischen, physiologischen, genetischen, psychischen, wirtschaftlichen, kulturellen oder
                            sozialen Identität dieser natürlichen Person sind, identifiziert werden kann.</p>

                        <p>2. Betroffene Person
                            Betroffene Person ist jede identifizierte oder identifizierbare natürliche Person, deren
                            personenbezogene Daten von dem für die Verarbeitung Verantwortlichen verarbeitet werden.</p>

                        <p>3. Verarbeitung
                            Verarbeitung ist jeder mit oder ohne Hilfe automatisierter Verfahren ausgeführte Vorgang
                            oder
                            jede solche Vorgangsreihe im Zusammenhang mit personenbezogenen Daten wie das Erheben, das
                            Erfassen, die Organisation, das Ordnen, die Speicherung, die Anpassung oder Veränderung, das
                            Auslesen, das Abfragen, die Verwendung, die Offenlegung durch Übermittlung, Verbreitung oder
                            eine andere Form der Bereitstellung, den Abgleich oder die Verknüpfung, die Einschränkung,
                            das
                            Löschen oder die Vernichtung.</p>

                        <p>4. Profiling
                            Profiling ist jede Art der automatisierten Verarbeitung personenbezogener Daten, die darin
                            besteht, dass diese personenbezogenen Daten verwendet werden, um bestimmte persönliche
                            Aspekte,
                            die sich auf eine natürliche Person beziehen, zu bewerten, insbesondere, um Aspekte
                            bezüglich
                            Arbeitsleistung, wirtschaftlicher Lage, Gesundheit, persönlicher Vorlieben, Interessen,
                            Zuverlässigkeit, Verhalten, Aufenthaltsort oder Ortswechsel dieser natürlichen Person zu
                            analysieren oder vorherzusagen.</p>

                        <p>5. Verantwortlicher oder für die Verarbeitung Verantwortlicher ist die natürliche oder
                            juristische Person, Behörde, Einrichtung oder andere Stelle, die allein oder gemeinsam mit
                            anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten entscheidet.
                            Sind die Zwecke und Mittel dieser Verarbeitung durch das Unionsrecht oder das Recht der
                            Mitgliedstaaten vorgegeben, so kann der Verantwortliche beziehungsweise können die
                            bestimmten
                            Kriterien seiner Benennung nach dem Unionsrecht oder dem Recht der Mitgliedstaaten
                            vorgesehen
                            werden.</p>

                        <p>6. Auftragsverarbeiter
                            Auftragsverarbeiter ist eine natürliche oder juristische Person, Behörde, Einrichtung oder
                            andere Stelle, die personenbezogene Daten im Auftrag des Verantwortlichen verarbeitet.</p>

                        <p>7. Empfänger
                            Empfänger ist eine natürliche oder juristische Person, Behörde, Einrichtung oder andere
                            Stelle,
                            der personenbezogene Daten offengelegt werden, unabhängig davon, ob es sich bei ihr um einen
                            Dritten handelt oder nicht. Behörden, die im Rahmen eines bestimmten Untersuchungsauftrags
                            nach
                            dem Unionsrecht oder dem Recht der Mitgliedstaaten möglicherweise personenbezogene Daten
                            erhalten, gelten jedoch nicht als Empfänger.</p>

                        <p>8. Einwilligung
                            Einwilligung ist jede von der betroffenen Person freiwillig für den bestimmten Fall in
                            informierter Weise und unmissverständlich abgegebene Willensbekundung in Form einer
                            Erklärung
                            oder einer sonstigen eindeutigen bestätigenden Handlung, mit der die betroffene Person zu
                            verstehen gibt, dass sie mit der Verarbeitung der sie betreffenden personenbezogenen Daten
                            einverstanden ist.</p>

                        <h3>III. Allgemeines zur Datenverarbeitung</h3>

                        <p>1. Umfang der Verarbeitung personenbezogener Daten
                            Wir erheben und verwenden personenbezogene Daten unserer Nutzer grundsätzlich nur, soweit
                            dies
                            zur Bereitstellung einer funktionsfähigen Internetseite sowie unserer Inhalte und Leistungen
                            erforderlich ist. Die Erhebung und Verwendung personenbezogener Daten unserer Nutzer erfolgt
                            regelmäßig nur nach Einwilligung des Nutzers. Eine Ausnahme gilt in solchen Fällen, in denen
                            eine vorherige Einholung einer Einwilligung aus tatsächlichen Gründen nicht möglich ist und
                            die
                            Verarbeitung der Daten durch gesetzliche Vorschriften gestattet ist.

                            <p>2. Rechtsgrundlage für die Verarbeitung personenbezogener Daten
                                Soweit wir für Verarbeitungsvorgänge personenbezogener Daten eine Einwilligung der
                                betroffenen Person einholen, dient Art. 6 Abs. 1 lit. a EU-Datenschutzgrundverordnung
                                (DSGVO) als Rechtsgrundlage für die Verarbeitung personenbezogener Daten.</p>

                            <p>Bei der Verarbeitung von personenbezogenen Daten, die zur Erfüllung eines Vertrages,
                                dessen Vertragspartei die betroffene Person ist, erforderlich ist, dient Art. 6 Abs. 1
                                lit. b DSGVO als Rechtsgrundlage. Dies gilt auch für Verarbeitungsvorgänge, die zur
                                Durchführung vorvertraglicher Maßnahmen erforderlich sind.</p>

                            <p>Soweit eine Verarbeitung personenbezogener Daten zur Erfüllung einer rechtlichen
                                Verpflichtung erforderlich ist, der unser Unternehmen unterliegt, dient Art. 6 Abs. 1
                                lit. c DSGVO als Rechtsgrundlage.</p>

                            <p>Für den Fall, dass lebenswichtige Interessen der betroffenen Person oder einer
                                anderen
                                natürlichen Person eine Verarbeitung personenbezogener Daten erforderlich machen,
                                dient
                                Art. 6 Abs. 1 lit. d DSGVO als Rechtsgrundlage.</p>

                            <p>Ist die Verarbeitung zur Wahrung eines berechtigten Interesses unseres
                                Unternehmens
                                oder
                                eines Dritten erforderlich und überwiegen die Interessen, Grundrechte und
                                Grundfreiheiten des Betroffenen das erstgenannte Interesse nicht, so dient Art.
                                6
                                Abs. 1
                                lit. f DSGVO als Rechtsgrundlage für die Verarbeitung.</p>


                            <p>Der Betroffene hat gem. gem. Artikel 21 DSGVO das Recht, aus Gründen, die sich
                                aus
                                seiner besonderen Situation ergeben, jederzeit gegen die Verarbeitung von ihn
                                betreffender personenbezogener Daten, die aufgrund von Artikel 6 Abs. 1 f) DSGVO
                                (Datenverarbeitung auf der Grundlage einer Interessenabwägung) erfolgt,
                                Widerspruch
                                einzulegen. Legt der Betroffene Widerspruch ein, wird der Verantwortliche seine
                                personenbezogenen Daten nicht mehr verarbeiten, es sei denn, der Verantwortliche
                                kann
                                zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die die
                                Interessen,
                                Rechte und Freiheiten des Betroffenen überwiegen, oder die Verarbeitung dient
                                der
                                Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen. Der Widerspruch
                                kann
                                formfrei erfolgen und sollte möglichst gerichtet werden an:</p>

                            <p>trobotics</p>
                            <p>Jahnplatz 3</p>
                            <p>53840 Troisdorf</p>
                            <p>EMail: info@trobotics.de</p>

                            <p>3. Datenlöschung und Speicherdauer
                                Die personenbezogenen Daten der betroffenen Person werden gelöscht oder
                                gesperrt,
                                sobald
                                der Zweck der Speicherung entfällt. Eine Speicherung kann darüber hinaus dann
                                erfolgen,
                                wenn dies durch den europäischen oder nationalen Gesetzgeber in
                                unionsrechtlichen
                                Verordnungen, Gesetzen oder sonstigen Vorschriften, denen der Verantwortliche
                                unterliegt, vorgesehen wurde. Eine Sperrung oder Löschung der Daten erfolgt auch
                                dann,
                                wenn eine durch die genannten Normen vorgeschriebene Speicherfrist abläuft, es
                                sei
                                denn,
                                dass eine Erforderlichkeit zur weiteren Speicherung der Daten für einen
                                Vertragsabschluss oder eine Vertragserfüllung besteht.</p>

                            <h3>IV. Bereitstellung der Internetseite und Erstellung von Logfiles</h3>

                            <p>1. Beschreibung und Umfang der Datenverarbeitung
                                Bei jedem Aufruf unserer Internetseite erfasst unser System automatisiert Daten
                                und
                                Informationen vom Computersystem des aufrufenden Rechners.</p>

                            <p>Folgende Daten werden hierbei erhoben:</p>

                            <p>(1) Informationen über den Browsertyp und die verwendete Version;</p>
                            <p>(2) Das Betriebssystem des Nutzers;</p>
                            <p>(3) Den Internet-Service-Provider des Nutzers;</p>
                            <p>(4) Die IP-Adresse des Nutzers;</p>
                            <p>(5) Datum und Uhrzeit des Zugriffs;</p>
                            <p>(6) Internetseiten, von denen das System des Nutzers auf unsere Internetseite
                                gelangt;</p>
                            <p>(7) Internetseiten, die vom System des Nutzers über unsere Internetseite
                                aufgerufen
                                werden.</p>

                            <p>Die Daten werden in den Logfiles unseres Systems gespeichert. Nicht hiervon
                                betroffen
                                sind die IP-Adressen des Nutzers oder andere Daten, die die Zuordnung der Daten
                                zu
                                einem
                                Nutzer ermöglichen. Eine Speicherung dieser Daten zusammen mit anderen
                                personenbezogenen
                                Daten des Nutzers findet nicht statt.</p>

                            <p>2. Rechtsgrundlage für die Datenverarbeitung
                                Rechtsgrundlage für die vorübergehende Speicherung der Daten ist Art. 6 Abs. 1
                                lit.
                                f
                                DSGVO.</p>

                            <p>3. Zweck der Datenverarbeitung
                                Die vorübergehende Speicherung der IP-Adresse durch das System ist notwendig, um
                                eine
                                Auslieferung der Internetseite an den Rechner des Nutzers zu ermöglichen.
                                Hierfür
                                muss
                                die IP-Adresse des Nutzers für die Dauer der Sitzung gespeichert bleiben.</p>

                            <p>In diesen Zwecken liegt auch unser berechtigtes Interesse an der
                                Datenverarbeitung
                                nach
                                Art. 6 Abs. 1 lit. f DSGVO.</p>

                            <p>4. Dauer der Speicherung
                                Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes ihrer
                                Erhebung
                                nicht mehr erforderlich sind. Im Falle der Erfassung der Daten zur
                                Bereitstellung
                                der
                                Internetseite ist dies der Fall, wenn die jeweilige Sitzung beendet ist.</p>

                            <p>5. Widerspruchs- und Beseitigungsmöglichkeit
                                Die Erfassung der Daten zur Bereitstellung der Internetseite und die Speicherung
                                der
                                Daten in Logfiles ist für den Betrieb der Internetseite zwingend erforderlich.
                                Es
                                besteht folglich seitens des Nutzers keine Widerspruchsmöglichkeit.</p>

                            <h3>V. Verwendung von Cookies</h3>

                            <p>1. Beschreibung und Umfang der Datenverarbeitung
                                Unsere Internetseite verwendet Cookies. Bei Cookies handelt es sich um
                                Textdateien,
                                die
                                im Internetbrowser bzw. vom Internetbrowser auf dem Computersystem des Nutzers
                                gespeichert werden. Ruft ein Nutzer eine Internetseite auf, so kann ein Cookie
                                auf
                                dem
                                Betriebssystem des Nutzers gespeichert werden. Dieser Cookie enthält eine
                                charakteristische Zeichenfolge, die eine eindeutige Identifizierung des Browsers
                                beim
                                erneuten Aufrufen der Internetseite ermöglicht.
                                Wir setzen Cookies ein, um unsere Internetseite nutzerfreundlicher zu gestalten.
                                Einige
                                Elemente unserer Internetseite erfordern es, dass der aufrufende Browser auch
                                nach
                                einem
                                Seitenwechsel identifiziert werden kann.</p>

                            <p>2. Rechtsgrundlage für die Datenverarbeitung
                                Die Rechtsgrundlage für die Verarbeitung personenbezogener Daten unter
                                Verwendung
                                von
                                Cookies ist Art. 6 Abs. 1 lit. f DSGVO. Einwilligung des Nutzers Art. 6 Abs. 1
                                lit.
                                a
                                DSGVO.</p>

                            <p>3. Zweck der Datenverarbeitung
                                Der Zweck der Verwendung technisch notwendiger Cookies ist, die Nutzung von
                                Internetseiten für die Nutzer zu vereinfachen. Einige Funktionen unserer
                                Internetseite
                                können ohne den Einsatz von Cookies nicht angeboten werden. Für diese ist es
                                erforderlich, dass der Browser auch nach einem Seitenwechsel wiedererkannt wird.</p>

                            <p>In diesen Zwecken liegt auch unser berechtigtes Interesse in der Verarbeitung
                                der
                                personenbezogenen Daten nach Art. 6 Abs. 1 lit. f DSGVO.</p>

                            <p>4. Dauer der Speicherung, Widerspruchs- und Beseitigungsmöglichkeit
                                Cookies werden auf dem Rechner des Nutzers gespeichert und von diesem an unserer
                                Seite
                                übermittelt. Daher haben Sie als Nutzer auch die volle Kontrolle über die
                                Verwendung
                                von
                                Cookies. Durch eine Änderung der Einstellungen in Ihrem Internetbrowser können
                                Sie
                                die
                                Übertragung von Cookies deaktivieren oder einschränken. Bereits gespeicherte
                                Cookies
                                können jederzeit gelöscht werden. Dies kann auch automatisiert erfolgen. Werden
                                Cookies
                                für unsere Internetseite deaktiviert, können möglicherweise nicht mehr alle
                                Funktionen
                                der Internetseite vollumfänglich genutzt werden.</p>

                            <h3>VI. Kontaktformular und E-Mail-Kontakt</h3>

                            <p>1. Beschreibung und Umfang der Datenverarbeitung
                                Auf unserer Internetseite sind Kontaktformulare vorhanden, welche für die
                                elektronische
                                Kontaktaufnahme genutzt werden können. Nimmt ein Nutzer diese Möglichkeit war,
                                so
                                werden
                                die in der Eingabemaske eingegeben Daten (Name, E-Mail-Adresse und Nachricht) an
                                uns
                                übermittelt und gespeichert. Darüber hinaus kann der Nutzer optional Angaben
                                machen
                                über
                                Vorname, Firma, Adresse, Land, zur Produktsuche und wie er auf uns aufmerksam
                                geworden
                                ist.</p>

                            <p>Alternativ ist eine Kontaktaufnahme über die bereitgestellte E-Mail-Adresse
                                möglich.
                                In
                                diesem Fall werden die mit der E-Mail übermittelten personenbezogenen Daten des
                                Nutzers
                                gespeichert.</p>

                            <p>Es verfolgt in diesem Zusammenhang keine Weitergabe der Daten an Dritte. Die
                                Daten
                                werden ausschließlich für die Verarbeitung der Konversation verwendet.</p>

                            <p>2. Rechtsgrundlage für die Datenverarbeitung
                                Rechtsgrundlage für die Verarbeitung der Daten ist Art. 6 Abs. 1 lit. b DSGVO.</p>

                            <p>3. Zweck der Datenverarbeitung
                                Die Verarbeitung der personenbezogenen Daten aus der Mail dient uns allein zur
                                Bearbeitung der Kontaktaufnahme.</p>

                            <p>4. Dauer der Speicherung
                                Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes ihrer
                                Erhebung
                                nicht mehr erforderlich sind. Für die personenbezogenen Daten aus der
                                Eingabemaske
                                des
                                Kontaktformulars und diejenigen, die per E-Mail übersandt wurden, ist dies dann
                                der
                                Fall, wenn die jeweilige Konversation mit dem Nutzer beendet ist. Beendet ist
                                die
                                Konversation dann, wenn sich aus den Umständen entnehmen lässt, dass der
                                betroffene
                                Sachverhalt abschließend geklärt ist.</p>

                            <h3>VII. Web Fonts Einbindung über die Monotype GmbH
                                (https://www.fonts.com)</h3>

                            <p>Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten so genannte Web
                                Fonts,
                                die von Monotype GmbH bereitgestellt werden. Beim Aufruf einer Seite lädt Ihr
                                Browser
                                die benötigten Web Fonts in ihren Browsercache, um Texte und Schriftarten
                                korrekt
                                anzuzeigen.</p>

                            <p>Zu diesem Zweck muss der von Ihnen verwendete Browser Verbindung zu den Servern
                                von
                                Monotype GmbH aufnehmen. Hierdurch erlangt Monotype GmbH Kenntnis darüber, dass
                                über
                                Ihre IP-Adresse unsere Website aufgerufen wurde. Die Nutzung von Monotype GmbH
                                Web
                                Fonts
                                erfolgt im Interesse einer einheitlichen und ansprechenden Darstellung unserer
                                Online-Angebote. Dies stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs.
                                1
                                lit.
                                f DSGVO dar.</p>

                            <p>Wenn Ihr Browser Web Fonts nicht unterstützt, wird eine Standardschrift von
                                Ihrem
                                Computer genutzt.</p>

                            <p>Weitere Informationen zu Monotype GmbH Web Fonts finden Sie in der
                                Datenschutzerklärung
                                der Monotype GmbHhttps://www.monotype.com/legal/privacy-policy</p>

                            <h3>VIII. Rechte der betroffenen Person</h3>

                            <p>Werden personenbezogene Daten von Ihnen verarbeitet, sind Sie betroffene Person
                                i.S.d.
                                DSGVO und es stehen Ihnen folgende Rechte gegenüber dem Verantwortlichen zu:</p>

                            <p>1. Auskunftsrecht
                                Sie können von dem Verantwortlichen eine Bestätigung darüber verlangen, ob
                                personenbezogene Daten, die Sie betreffen, von uns verarbeitet werden.</p>

                            <p>Liegt eine solche Verarbeitung vor, können Sie von dem Verantwortlichen über
                                folgende
                                Informationen Auskunft verlangen:</p>

                            <p>(1) die Zwecke, zu denen die personenbezogenen Daten verarbeitet werden;</p>
                            <p>(2) die Kategorien von personenbezogenen Daten, welche verarbeitet werden;</p>
                            <p>(3) die Empfänger bzw. die Kategorien von Empfängern, gegenüber denen die Sie
                                betreffenden personenbezogenen Daten offengelegt wurden oder noch offengelegt
                                werden;</p>
                            <p>(4) die geplante Dauer der Speicherung der Sie betreffenden personenbezogenen
                                Daten
                                oder, falls konkrete Angaben hierzu nicht möglich sind, Kriterien für die
                                Festlegung
                                der
                                Speicherdauer;</p>
                            <p>(5) das Bestehen eines Rechts auf Berichtigung oder Löschung der Sie
                                betreffenden
                                personenbezogenen Daten, eines Rechts auf Einschränkung der Verarbeitung durch
                                den
                                Verantwortlichen oder eines Widerspruchsrechts gegen diese Verarbeitung;</p>
                            <p>(6) das Bestehen eines Beschwerderechts bei einer Aufsichtsbehörde;</p>
                            <p>(7) alle verfügbaren Informationen über die Herkunft der Daten, wenn die
                                personenbezogenen Daten nicht bei der betroffenen Person erhoben werden;</p>
                            <p>(8) das Bestehen einer automatisierten Entscheidungsfindung einschließlich
                                Profiling
                                gemäß Art. 22 Abs. 1 und 4 DSGVO und – zumindest in diesen Fällen –
                                aussagekräftige
                                Informationen über die involvierte Logik sowie die Tragweite und die
                                angestrebten
                                Auswirkungen einer derartigen Verarbeitung für die betroffene Person.</p>

                            <p>Ihnen steht das Recht zu, Auskunft darüber zu verlangen, ob die Sie betreffenden
                                personenbezogenen Daten in ein Drittland oder an eine internationale
                                Organisation
                                übermittelt werden. In diesem Zusammenhang können Sie verlangen, über die
                                geeigneten
                                Garantien gem. Art. 46 DSGVO im Zusammenhang mit der Übermittlung unterrichtet
                                zu
                                werden.</p>

                            <p>2. Recht auf Berichtigung
                                Sie haben ein Recht auf Berichtigung und/oder Vervollständigung gegenüber dem
                                Verantwortlichen, sofern die verarbeiteten personenbezogenen Daten, die Sie
                                betreffen,
                                unrichtig oder unvollständig sind. Der Verantwortliche hat die Berichtigung
                                unverzüglich
                                vorzunehmen.</p>

                            <p>3. Recht auf Einschränkung der Verarbeitung
                                Unter den folgenden Voraussetzungen können Sie die Einschränkung der
                                Verarbeitung
                                der
                                Sie betreffenden personenbezogenen Daten verlangen:</p>

                            <p>(1) wenn Sie die Richtigkeit der Sie betreffenden personenbezogenen für eine
                                Dauer
                                bestreiten, die es dem Verantwortlichen ermöglicht, die Richtigkeit der
                                personenbezogenen Daten zu überprüfen;</p>
                            <p>(2) die Verarbeitung unrechtmäßig ist und Sie die Löschung der personenbezogenen
                                Daten
                                ablehnen und stattdessen die Einschränkung der Nutzung der personenbezogenen
                                Daten
                                verlangen;</p>
                            <p>(3) der Verantwortliche die personenbezogenen Daten für die Zwecke der
                                Verarbeitung
                                nicht länger benötigt, Sie diese jedoch zur Geltendmachung, Ausübung oder
                                Verteidigung
                                von Rechtsansprüchen benötigen, oder</p>
                            <p>(4) wenn Sie Widerspruch gegen die Verarbeitung gemäß Art. 21 Abs. 1 DSGVO
                                eingelegt
                                haben und noch nicht feststeht, ob die berechtigten Gründe des Verantwortlichen
                                gegenüber Ihren Gründen überwiegen.</p>

                            <p>Wurde die Verarbeitung der Sie betreffenden personenbezogenen Daten
                                eingeschränkt,
                                dürfen diese Daten – von ihrer Speicherung abgesehen – nur mit Ihrer
                                Einwilligung
                                oder
                                zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen oder zum
                                Schutz
                                der
                                Rechte einer anderen natürlichen oder juristischen Person oder aus Gründen eines
                                wichtigen öffentlichen Interesses der Union oder eines Mitgliedstaats
                                verarbeitet
                                werden.</p>

                            <p>Wurde die Einschränkung der Verarbeitung nach den o.g. Voraussetzungen
                                eingeschränkt,
                                werden Sie von dem Verantwortlichen unterrichtet bevor die Einschränkung
                                aufgehoben
                                wird.</p>

                            <p>4. Recht auf Löschung</p>

                            <p></p>a) Löschungspflicht
                            Sie können von dem Verantwortlichen verlangen, dass die Sie betreffenden
                            personenbezogenen Daten unverzüglich gelöscht werden, und der Verantwortliche
                            ist
                            verpflichtet, diese Daten unverzüglich zu löschen, sofern einer der folgenden
                            Gründe
                            zutrifft:
                        </p>

                        <p></p>(1) Die Sie betreffenden personenbezogenen Daten sind für die Zwecke, für die
                        sie
                        erhoben oder auf sonstige Weise verarbeitet wurden, nicht mehr notwendig.
                    </p>
                    <p>(2) Sie widerrufen Ihre Einwilligung, auf die sich die Verarbeitung gem. Art. 6
                        Abs.
                        1
                        lit. a oder Art. 9 Abs. 2 lit. a DSGVO stützte, und es fehlt an einer
                        anderweitigen
                        Rechtsgrundlage für die Verarbeitung.</p>
                    <p>(3) Sie legen gem. Art. 21 Abs. 1 DSGVO Widerspruch gegen die Verarbeitung ein
                        und
                        es
                        liegen keine vorrangigen berechtigten Gründe für die Verarbeitung vor, oder Sie
                        legen
                        gem. Art. 21 Abs. 2 DSGVO Widerspruch gegen die Verarbeitung ein.</p>
                    <p>(4) Die Sie betreffenden personenbezogenen Daten wurden unrechtmäßig
                        verarbeitet.</p>
                    <p>(5) Die Löschung der Sie betreffenden personenbezogenen Daten ist zur Erfüllung
                        einer
                        rechtlichen Verpflichtung nach dem Unionsrecht oder dem Recht der
                        Mitgliedstaaten
                        erforderlich, dem der Verantwortliche unterliegt.</p>
                    <p>(6) Die Sie betreffenden personenbezogenen Daten wurden in Bezug auf angebotene
                        Dienste
                        der Informationsgesellschaft gemäß Art. 8 Abs. 1 DSGVO erhoben.</p>

                    <p> b) Information an Dritte
                        Hat der Verantwortliche die Sie betreffenden personenbezogenen Daten öffentlich
                        gemacht
                        und ist er gem. Art. 17 Abs. 1 DSGVO zu deren Löschung verpflichtet, so trifft
                        er
                        unter
                        Berücksichtigung der verfügbaren Technologie und der Implementierungskosten
                        angemessene
                        Maßnahmen, auch technischer Art, um für die Datenverarbeitung Verantwortliche,
                        die
                        die
                        personenbezogenen Daten verarbeiten, darüber zu informieren, dass Sie als
                        betroffene
                        Person von ihnen die Löschung aller Links zu diesen personenbezogenen Daten oder
                        von
                        Kopien oder Replikationen dieser personenbezogenen Daten verlangt haben.</p>

                    <p>c) Ausnahmen
                        Das Recht auf Löschung besteht nicht, soweit die Verarbeitung erforderlich ist</p>

                    <p>(1) zur Ausübung des Rechts auf freie Meinungsäußerung und Information;</p>
                    <p>(2) zur Erfüllung einer rechtlichen Verpflichtung, die die Verarbeitung nach dem
                        Recht
                        der Union oder der Mitgliedstaaten, dem der Verantwortliche unterliegt,
                        erfordert,
                        oder
                        zur Wahrnehmung einer Aufgabe, die im öffentlichen Interesse liegt oder in
                        Ausübung
                        öffentlicher Gewalt erfolgt, die dem Verantwortlichen übertragen wurde;</p>
                    <p>(3) aus Gründen des öffentlichen Interesses im Bereich der öffentlichen
                        Gesundheit
                        gemäß
                        Art. 9 Abs. 2 lit. h und i sowie Art. 9 Abs. 3 DSGVO;</p>
                    <p>(4) für im öffentlichen Interesse liegende Archivzwecke, wissenschaftliche oder
                        historische Forschungszwecke oder für statistische Zwecke gem. Art. 89 Abs. 1
                        DSGVO,
                        soweit das unter Abschnitt a) genannte Recht voraussichtlich die Verwirklichung
                        der
                        Ziele dieser Verarbeitung unmöglich macht oder ernsthaft beeinträchtigt, oder</p>
                    <p>(5) zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.</p>

                    <p>5. Recht auf Unterrichtung
                        Haben Sie das Recht auf Berichtigung, Löschung oder Einschränkung der
                        Verarbeitung
                        gegenüber dem Verantwortlichen geltend gemacht, ist dieser verpflichtet, allen
                        Empfängern, denen die Sie betreffenden personenbezogenen Daten offengelegt
                        wurden,
                        diese
                        Berichtigung oder Löschung der Daten oder Einschränkung der Verarbeitung
                        mitzuteilen, es
                        sei denn, dies erweist sich als unmöglich oder ist mit einem unverhältnismäßigen
                        Aufwand
                        verbunden.</p>

                    <p>Ihnen steht gegenüber dem Verantwortlichen das Recht zu, über diese Empfänger
                        unterrichtet zu werden.</p>

                    <p>6. Recht auf Datenübertragbarkeit
                        Sie haben das Recht, die Sie betreffenden personenbezogenen Daten, die Sie dem
                        Verantwortlichen bereitgestellt haben, in einem strukturierten, gängigen und
                        maschinenlesbaren Format zu erhalten. Außerdem haben Sie das Recht diese Daten
                        einem
                        anderen Verantwortlichen ohne Behinderung durch den Verantwortlichen, dem die
                        personenbezogenen Daten bereitgestellt wurden, zu übermitteln, sofern</p>

                    <p>(1) die Verarbeitung auf einer Einwilligung gem. Art. 6 Abs. 1 lit. a DSGVO oder
                        Art. 9
                        Abs. 2 lit. a DSGVO oder auf einem Vertrag gem. Art. 6 Abs. 1 lit. b DSGVO
                        beruht
                        und</p>
                    <p>(2) die Verarbeitung mithilfe automatisierter Verfahren erfolgt.</p>

                    <p>In Ausübung dieses Rechts haben Sie ferner das Recht, zu erwirken, dass die Sie
                        betreffenden personenbezogenen Daten direkt von einem Verantwortlichen einem
                        anderen
                        Verantwortlichen übermittelt werden, soweit dies technisch machbar ist.
                        Freiheiten
                        und
                        Rechte anderer Personen dürfen hierdurch nicht beeinträchtigt werden.</p>

                    <p>Das Recht auf Datenübertragbarkeit gilt nicht für eine Verarbeitung
                        personenbezogener
                        Daten, die für die Wahrnehmung einer Aufgabe erforderlich ist, die im
                        öffentlichen
                        Interesse liegt oder in Ausübung öffentlicher Gewalt erfolgt, die dem
                        Verantwortlichen
                        übertragen wurde.</p>

                    <p>7. Widerspruchsrecht
                        Sie haben das Recht, aus Gründen, die sich aus ihrer besonderen Situation
                        ergeben,
                        jederzeit gegen die Verarbeitung der Sie betreffenden personenbezogenen Daten,
                        die
                        aufgrund von Art. 6 Abs. 1 lit. e oder f DSGVO erfolgt, Widerspruch einzulegen;
                        dies
                        gilt auch für ein auf diese Bestimmungen gestütztes Profiling.</p>

                    <p>Der Verantwortliche verarbeitet die Sie betreffenden personenbezogenen Daten
                        nicht
                        mehr,
                        es sei denn, er kann zwingende schutzwürdige Gründe für die Verarbeitung
                        nachweisen,
                        die
                        Ihre Interessen, Rechte und Freiheiten überwiegen, oder die Verarbeitung dient
                        der
                        Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.</p>

                    <p>Werden die Sie betreffenden personenbezogenen Daten verarbeitet, um
                        Direktwerbung zu
                        betreiben, haben Sie das Recht, jederzeit Widerspruch gegen die Verarbeitung der
                        Sie
                        betreffenden personenbezogenen Daten zum Zwecke derartiger Werbung einzulegen;
                        dies
                        gilt
                        auch für das Profiling, soweit es mit solcher Direktwerbung in Verbindung steht.</p>

                    <p>Widersprechen Sie der Verarbeitung für Zwecke der Direktwerbung, so werden die
                        Sie
                        betreffenden personenbezogenen Daten nicht mehr für diese Zwecke verarbeitet.</p>

                    <p>Sie haben die Möglichkeit, im Zusammenhang mit der Nutzung von Diensten der
                        Informationsgesellschaft – ungeachtet der Richtlinie 2002/58/EG – Ihr
                        Widerspruchsrecht
                        mittels automatisierter Verfahren auszuüben, bei denen technische
                        Spezifikationen
                        verwendet werden.</p>

                    <p>8. Recht auf Widerruf der datenschutzrechtlichen Einwilligungserklärung
                        Sie haben das Recht, Ihre datenschutzrechtliche Einwilligungserklärung jederzeit
                        zu
                        widerrufen. Durch den Widerruf der Einwilligung wird die Rechtmäßigkeit der
                        aufgrund
                        der
                        Einwilligung bis zum Widerruf erfolgten Verarbeitung nicht berührt.</p>

                    <p>9. Automatisierte Entscheidung im Einzelfall einschließlich Profiling
                        Sie haben das Recht, nicht einer ausschließlich auf einer automatisierten
                        Verarbeitung –
                        einschließlich Profiling – beruhenden Entscheidung unterworfen zu werden, die
                        Ihnen
                        gegenüber rechtliche Wirkung entfaltet oder Sie in ähnlicher Weise erheblich
                        beeinträchtigt. Dies gilt nicht, wenn die Entscheidung</p>

                    <p>(1) für den Abschluss oder die Erfüllung eines Vertrags zwischen Ihnen und dem
                        Verantwortlichen erforderlich ist,</p>
                    <p>(2) aufgrund von Rechtsvorschriften der Union oder der Mitgliedstaaten, denen
                        der
                        Verantwortliche unterliegt, zulässig ist und diese Rechtsvorschriften
                        angemessene
                        Maßnahmen zur Wahrung Ihrer Rechte und Freiheiten sowie Ihrer berechtigten
                        Interessen
                        enthalten oder</p>
                    <p>(3) mit Ihrer ausdrücklichen Einwilligung erfolgt.</p>

                    <p>Allerdings dürfen diese Entscheidungen nicht auf besonderen Kategorien
                        personenbezogener
                        Daten nach Art. 9 Abs. 1 DSGVO beruhen, sofern nicht Art. 9 Abs. 2 lit. a oder g
                        gilt
                        und angemessene Maßnahmen zum Schutz der Rechte und Freiheiten sowie Ihrer
                        berechtigten
                        Interessen getroffen wurden.</p>

                    <p>Hinsichtlich der in (1) und (3) genannten Fälle trifft der Verantwortliche
                        angemessene
                        Maßnahmen, um die Rechte und Freiheiten sowie Ihre berechtigten Interessen zu
                        wahren,
                        wozu mindestens das Recht auf Erwirkung des Eingreifens einer Person seitens des
                        Verantwortlichen, auf Darlegung des eigenen Standpunkts und auf Anfechtung der
                        Entscheidung gehört.</p>

                    <p>10. Recht auf Beschwerde bei einer Aufsichtsbehörde
                        Unbeschadet eines anderweitigen verwaltungsrechtlichen oder gerichtlichen
                        Rechtsbehelfs
                        steht Ihnen das Recht auf Beschwerde bei einer Aufsichtsbehörde, insbesondere in
                        dem
                        Mitgliedstaat ihres Aufenthaltsorts, ihres Arbeitsplatzes oder des Orts des
                        mutmaßlichen
                        Verstoßes, zu, wenn Sie der Ansicht sind, dass die Verarbeitung der Sie
                        betreffenden
                        personenbezogenen Daten gegen die DSGVO verstößt.</p>

                    <p>Die Aufsichtsbehörde, bei der die Beschwerde eingereicht wurde, unterrichtet den
                        Beschwerdeführer über den Stand und die Ergebnisse der Beschwerde einschließlich
                        der
                        Möglichkeit eines gerichtlichen Rechtsbehelfs nach Art. 78 DSGVO.
                    </p>
                </TextFont>
            </Main>
            <Footer/>
        </Body>
    )
}

const TextFont = styled.section`
    font-family: var(--textfieldFont);
    `