import styled from "styled-components";


export default function Line (){

    return (
        <Wrapper/>
    )
}

const Wrapper = styled.div`
    height: 1px;
    background: var(--backgroundlight);
    `