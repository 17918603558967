import { NavLink } from "react-router-dom";
import styled from "styled-components";


export default function NavBar() {
    //const [isOpen, setIsOpen] = useState(false);

    return (
/*        <NavContainer>
            <Burger onClick={() => setIsOpen(!isOpen)}>
                {isOpen ? <FiX /> : <FiMenu />}
            </Burger>
            <Menu isOpen={isOpen}>
                <NavLink to="/" onClick={() => setIsOpen(false)}>Home</NavLink>
                <NavLink to="/consulting" onClick={() => setIsOpen(false)}>Beratung</NavLink>
                <NavLink to="/sales" onClick={() => setIsOpen(false)}>Verkauf</NavLink>
                <NavLink to="/rent" onClick={() => setIsOpen(false)}>Vermietung</NavLink>
                <NavLink to="/solutions" onClick={() => setIsOpen(false)}>Technical Solutions</NavLink>
                <NavLink to="/contact" onClick={() => setIsOpen(false)}>Kontakt</NavLink>
                <NavLink to="/aboutus" onClick={() => setIsOpen(false)}>Über uns</NavLink>
            </Menu>
        </NavContainer>*/
        <Wrapper>
            <NavLink to="/" >Home</NavLink>
            <NavLink to="/consulting" >Beratung</NavLink>
            <NavLink to="/sales" >Verkauf</NavLink>
            <NavLink to="/rent" >Vermietung</NavLink>
            <NavLink to="/solutions" >Technical Solutions</NavLink>
            <NavLink to="/contact" >Kontakt</NavLink>
            {/*<NavLink to="/aboutus" >Über uns</NavLink>*/}
        </Wrapper>
    );
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    color: black;
    font-size: 0.8rem;
    background-color: var(--backgroundlight);
    
    a{
        color: black;
        text-decoration: none;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 5px;
    }
    
    a.active {
    color: dimgray;
    }

    a:hover {
    color: dimgray;
    }

`
/*
/!* Container für die Navigation *!/
const NavContainer = styled.nav`
    display: flex;
    align-items: center;
    background: var(--backgroundlight);
    padding: 10px 20px;
    position: relative;
`;

/!* Burger-Button *!/
const Burger = styled.div`
    padding-left: 30px;
    font-size: 30px;
    cursor: pointer;
    color: dimgray;
    position: absolute;
    left: 10px;
    z-index: 10000;
`;

/!* Menü-Styling *!/
const Menu = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 250px;
    height: 100vh;
    background: rgba(0, 0, 0, 0.9);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    transform: ${({ isOpen }) => (isOpen ? "translateX(0)" : "translateX(-100%)")};
    transition: transform 0.3s ease-in-out;

    a {
        color: white;
        text-decoration: none;
        font-size: 22px;
        margin: 15px 0;
        transition: color 0.3s ease-in-out;
    }

    a:hover {
        color: lightgray;
    }
`;
*/



/*AuskommentiertesBurgerMenu*/

/*

import {useState} from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import {FiX, FiMenu} from "react-icons/fi";

export default function NavBar() {
    const [isOpen, setIsOpen] = useState(false);

    return (
                <NavContainer>
                    <Burger onClick={() => setIsOpen(!isOpen)}>
                        {isOpen ? <FiX /> : <FiMenu />}
                    </Burger>
                    <Menu isOpen={isOpen}>
                        <NavLink to="/" onClick={() => setIsOpen(false)}>Home</NavLink>
                        <NavLink to="/consulting" onClick={() => setIsOpen(false)}>Beratung</NavLink>
                        <NavLink to="/sales" onClick={() => setIsOpen(false)}>Verkauf</NavLink>
                        <NavLink to="/rent" onClick={() => setIsOpen(false)}>Vermietung</NavLink>
                        <NavLink to="/solutions" onClick={() => setIsOpen(false)}>Technical Solutions</NavLink>
                        <NavLink to="/contact" onClick={() => setIsOpen(false)}>Kontakt</NavLink>
                        <NavLink to="/aboutus" onClick={() => setIsOpen(false)}>Über uns</NavLink>
                    </Menu>
                </NavContainer>
    );
}

/!* Container für die Navigation *!/
const NavContainer = styled.nav`
    display: flex;
    align-items: center;
    background: var(--backgroundlight);
    padding: 10px 20px;
    position: relative;
`;

/!* Burger-Button *!/
const Burger = styled.div`
    padding-left: 30px;
    font-size: 30px;
    cursor: pointer;
    color: dimgray;
    position: absolute;
    left: 10px;
    z-index: 10000;
`;

/!* Menü-Styling *!/
const Menu = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 250px;
    height: 100vh;
    background: rgba(0, 0, 0, 0.9);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    transform: ${({ isOpen }) => (isOpen ? "translateX(0)" : "translateX(-100%)")};
    transition: transform 0.3s ease-in-out;

    a {
        color: white;
        text-decoration: none;
        font-size: 22px;
        margin: 15px 0;
        transition: color 0.3s ease-in-out;
    }

    a:hover {
        color: lightgray;
    }
`;
*/
