import React from "react";
import styled from "styled-components";
import GlobalStyles from "../components/GlobalStyles";
import Logo from "./Logo";

export default function SiteHeader( {caption} ) {

    return (
        <>
            <GlobalStyles/>
            <Wrapper>
                <section className="logo">
                <Logo/>
                </section>
                <section className="header-description">
                    {caption}
                </section>
            </Wrapper>
        </>
    );
}

const Wrapper = styled.header`
    display: flex;
    align-items: center;
    text-align: center;
    max-height: 80px;
    width: 100%;
    padding: 20px;
    background: var(--backgroundlight);
    color: var(--foreground);
    position: relative; /* Wichtig für absolute Positionierung der Beschreibung */

    .logo {
        padding-left: 40px;
    }

    .header-description {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        font-size: 16px;
        font-weight: normal;
        color: black;
    }

    @media (max-width: 767px) { /* Anpassung für mobile Geräte */
        flex-direction: column;
        align-items: center;
        text-align: center;

        .logo {
/*            width: 50px; !* Logo kleiner *!
            height: auto;
            margin-bottom: 10px; !* Nach oben verschieben *!*/

             //Falls das Logo auf mobilen Geräten entfernt werden soll 
             display: none; 
        }

        .header-description {
            position: relative; /* Keine absolute Positionierung auf mobilen Geräten */
            left: auto;
            transform: none;
            font-size: 1.2rem;
        }
    }

    @media (min-width: 768px) {
        .header-description {
            font-size: 1.5rem;
        }
    }

    @media (min-width: 1024px) {
        .header-description {
            font-size: 1.5rem;
        }
    }
`;
