import Line from "../components/Line";
import NavBar from "../components/NavBar";
import Body from "../components/Body";
import Main from "../components/Main";
import Footer from "../components/Footer";
import Slideshow from "../components/Slideshow";
import BigPic1 from "../components/Files/tom2.png";
import BigPic2 from "../components/Files/con.JPG";
import BigPic3 from "../components/Files/con1.JPG";
import TextField from "../components/TextField";
import SiteHeader from "../components/SiteHeader";

export default function Consulting() {


    return (
        <Body>
            <section>
                <SiteHeader caption={"Beratung"}/>
                <Line/>
                <NavBar/>
                <Line/>
            </section>
            <Main>
                <Slideshow bigPic1={BigPic1} bigPic2={BigPic2} bigPic3={BigPic3}
                           captions={[
                               {text: "Beratung für Ihr Unternehmen", top: "20%", left: "10%", opacity: 0.8},
                               {text: "Strategische Planung", bottom: "15%", right: "10%", opacity: 0.8},
                               {text: "Erfolgreiche Umsetzung", top: "50%", left: "10%", opacity: 0.8}
                           ]}
                />
                <TextField>
                    <section className="text_left">
                        <p>
                            <span style={{fontSize: "2em", display: "inline-block", marginBottom: "-0.5em"}}>S</span>ie
                            sind interessiert an etwaigen Einsatzöglichkeiten von Cobots in Ihrem Unternehmen? Wir
                            beraten Sie kompetent, wie und in welchem Umfang Sie Cobots optimal in Ihr Unternehmen
                            integrieren können.<br/>
                            Kommen Sie mit Ihren Fragen gerne auf uns zu.
                        </p>
                    </section>
                    <section className="text_right">
                        <section className="video_screen">
                            <iframe
                                className="responsive_iframe"
                                src="https://www.youtube.com/embed/8YAbYQH_q7w?si=NBHCtYaXY_YzetQ0"
                                title="YouTube video player" frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                        </section>
                    </section>
                </TextField>
            </Main>
            <Footer/>
        </Body>
    )
}
