import { useEffect } from "react";

export default function ScrollToTop() {

    useEffect(() => {
        setTimeout(() => {
            window.scrollTo({ top: 0, behavior: "smooth" });
        }, 0);
    }, []);

    return null;
}
