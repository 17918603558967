import Line from "../components/Line";
import NavBar from "../components/NavBar";
import Body from "../components/Body";
import Main from "../components/Main";
import Footer from "../components/Footer";
import Slideshow from "../components/Slideshow";
import BigPic1 from "../components/Files/solution.png"
import BigPic2 from "../components/Files/tech1.JPG";
import BigPic3 from "../components/Files/hq720.jpg";
import BigPic4 from "../components/Files/tech.JPG";
import TextField from "../components/TextField";
import SiteHeader from "../components/SiteHeader";



export default function TechnicalSolutions() {


    return (
        <Body>
            <section>
                <SiteHeader caption={"Technical Solutions"}/>
                <Line/>
                <NavBar/>
                <Line/>
            </section>
            <Main>
                <Slideshow bigPic1={BigPic1} bigPic2={BigPic2} bigPic3={BigPic3} bigPic4={BigPic4}
                           captions={[
                               {text: "Programmierung", top: "10%", left: "5%", opacity: 0.8},
                               {text: "Automatisierung", bottom: "15%", right: "10%", opacity: 0.8},
                               {text: "Implementierung", top: "50%", left: "20%", opacity: 0.8},
                               {text: "Innovative Umsetzung", top: "50%", left: "20%", opacity: 0.8}
                           ]}
                />
                <TextField>
                    <section className="text_left">
                        <p>
                            <span style={{fontSize: "2em", display: "inline-block", marginBottom: "-0.5em"}}>W</span>ir
                            entwickeln mit Ihnen zusammen individuell auf Sie angepasste technische Lösungen.
                            Entsprechend Ihrer Anforderung entwickeln wir umfassende Einsatzszenarien von Cobots
                            in Ihren Prozessen, um diese effizient zu gestalten. Hier kommt Ihnen die Expertise von
                            langjährigen Erfahrungen in der Einbindung und dem Aufbau von Cobots zugute. Diese reicht von
                            einfachen Einsatzmöglichkeiten bis hin zu komplexen automatisierten Implementierungen.
                        </p>
                    </section>
                    <section className="text_right">
                        <section className="video_screen">
                            <iframe
                                className="responsive_iframe"
                                src="https://www.youtube.com/embed/HODJ0g7MtNs?si=mXavjKePzIHny_ov"
                                title="YouTube video player" frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                        </section>
                    </section>
                </TextField>
            </Main>
            <Footer/>
        </Body>
    )
}