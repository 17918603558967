import {createGlobalStyle} from "styled-components";

export default createGlobalStyle`
    
    
    :root {
        --backgrounddark: lightgrey;
        --backgroundlight: whitesmoke;
        //--tilecolor: #BCEBF7;
        --tilecolor: white;
        --backgroundmain: white;

        //--backgrounddark: green;
        //--backgroundlight: lightgreen;
        //--tilecolor: lightgreen;

        --forground: white;
        --buttoncolor: lightgrey;
        --bordercolor: dimgrey;
        --textcolor: black;
        --font: "Orbitron", sans-serif;
        --textfieldFont: "Exo", sans-serif;

        body{
            font-family:var(--font),sans-serif;
        }

    }
`;


