import {useState, useEffect} from "react";
import styled from "styled-components";


export default function Slideshow({bigPic1, bigPic2, bigPic3, bigPic4, captions, button}) {
    const [currentIndex, setCurrentIndex] = useState(0);

    const images = [bigPic1, bigPic2, bigPic3, bigPic4].filter(Boolean);

    // Automatischer Bildwechsel alle 3 Sekunden
    useEffect(() => {
        if (images.length <= 1) return;
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 3000);
        return () => clearInterval(interval);
    }, [images.length]);

    const nextSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    const prevSlide = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? images.length - 1 : prevIndex - 1
        );
    };

    return (
        <SlideshowContainer>
            {button===true &&
            <SlideButton onClick={prevSlide} side="left">‹</SlideButton>
            }
            <img src={images[currentIndex]} alt={`Slide ${currentIndex + 1}`} className="slide"/>
            {captions &&
                <Caption style={captions[currentIndex]}>{captions[currentIndex].text}</Caption>
            }
            {button===true &&
            <SlideButton onClick={nextSlide} side="right">›</SlideButton>
            }
        </SlideshowContainer>
    );
}

const Caption = styled.div`
    position: absolute;
    font-size: 6rem;
    font-weight: bold;
    color: white;
    //background: rgba(0, 0, 0, 0.5);
    padding: 10px 15px;
    border-radius: 5px;
    opacity: 0.8;
    transition: opacity 0.5s ease-in-out;
    
    @media (max-width: 768px) {
        font-size: 4rem; /* Kleinere Höhe für mobile Geräte */
    }

    @media (max-width: 480px) {
        font-size: 2rem; /* Noch kleinere Höhe für sehr kleine Displays */
    }
`;

const SlideshowContainer = styled.div`
    position: relative;
    width: 100%;
    height: 500px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    padding-left: 20px;
    padding-right: 20px;

    .slide {
        width: 100%;
        height: 100%; /* Fixierte Höhe */
        object-fit: cover;
        border-radius: 10px;
        transition: opacity 0.5s ease-in-out;
    }
    
    @media (max-width: 768px) {
        height: 300px; /* Kleinere Höhe für mobile Geräte */
    }

    @media (max-width: 480px) {
        height: 200px; /* Noch kleinere Höhe für sehr kleine Displays */
    }
`;

const SlideButton = styled.button`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: var(--backgrounddark);
    color: white;
    border: none;
    font-size: 2rem;
    cursor: pointer;
    padding: 10px;
    z-index: 10;

    ${({side}) => side === "left" ? "left: 30px;" : "right: 30px;"}
    &:hover {
        background: rgba(0, 0, 0, 0.7);
    }

`;
