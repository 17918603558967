import Line from "../components/Line";
import NavBar from "../components/NavBar";
import Body from "../components/Body";
import Main from "../components/Main";
import Footer from "../components/Footer";
import Slideshow from "../components/Slideshow";
import BigPic1 from "../components/Files/tech2.JPG";
import BigPic2 from "../components/Files/con1.JPG";
import TextField from "../components/TextField";
import SiteHeader from "../components/SiteHeader";
import Video from "../components/Files/tro_vid.mp4";


export default function Sales() {


    return (
        <Body>
            <section>
                <SiteHeader caption={"Verkauf"}/>
                <Line/>
                <NavBar/>
                <Line/>
            </section>
            <Main>
                <Slideshow bigPic1={BigPic1} bigPic2={BigPic2} bigPic3={BigPic1}
                           captions={[
                               {text: "Verkauf und Support", top: "10%", left: "5%", opacity: 0.8},
                               {text: "Maßgeschneiderte Produkte", bottom: "15%", right: "10%", opacity: 0.8},
                               {text: "Verkauf und Support", top: "10%", left: "5%", opacity: 0.8}
                           ]}
                />
                <TextField>
                    <section className="text_left">
                        <p>
                            <span style={{fontSize: "2em", display: "inline-block", marginBottom: "-0.5em"}}>S</span>ie
                            wissen bereits, dass Sie einen Cobot erwerben möchten? Durch unsere Expertise können wir
                            Ihnen bei der Auswahl des für Sie optimalen
                            Modells behilflich sein und stellen Ihnen gerne die aktuellen Preise der jeweiligen Modelle
                            zur Verfügung. Wir unterstützen Sie auch bei der
                            Handhabung und leisten Support bei weitergehenden Fragen.
                        </p>
                    </section>
                    <section className="text_right">
                        <video autoPlay muted playsInline loop>
                            <source src={Video} type="video/webm"/>
                        </video>
                    </section>
                </TextField>
            </Main>
            <Footer/>
        </Body>
    )
}