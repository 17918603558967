import styled from "styled-components";

const Description = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.7rem;
    text-align: center;
    font-weight: bold;
    opacity: ${({ isHovered }) => (isHovered ? "1" : "0")};
    transition: opacity 0.5s ease-in-out;

    @media (max-width: 1400px) {
        text-align: center;
        font-size: 1.5rem;
        opacity: 0.7; /* Immer sichtbar auf Mobilgeräten */
    }

    @media (max-width: 1024px) {
        text-align: center;
        font-size: 1.3rem;
        opacity: 0.7; /* Immer sichtbar auf Mobilgeräten */
    }
    
    @media (max-width: 768px) {
        text-align: center;
        font-size: 1rem;
        opacity: 0.7; /* Immer sichtbar auf Mobilgeräten */
    }

    @media (max-width: 480px) {
        text-align: center;
        font-size: 0.5rem;
        opacity: 0.7; /* Immer sichtbar auf Mobilgeräten */
    }
`;

export default Description;
