import styled from "styled-components";

export default styled.main`
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    justify-content: center;
    padding: 20px;
    
    `

