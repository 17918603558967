import styled from "styled-components";

export default styled.div`
    display: flex;
    flex-direction: row;
    margin: 10px;
    padding: 10px;
    text-align: justify;
    font-family: var(--textfieldFont);
    color: black;
    font-size: 1.5em;
    line-height: 2;
    font-weight: 200;

    /* Textblöcke */
    .text_left, .text_right {
        width: 50%;
        padding: 20px;
    }

    .text_left {
        padding-right: 40px;
    }

    .text_right {
        padding-left: 40px;
    }
    
    video{
        height: auto;
        width: 100%;
    }

    .profile_container {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        padding-top: 60px;
        padding-left: 100px;
        flex-wrap: wrap; /* Für bessere Darstellung auf kleinen Bildschirmen */
    }

    .profile {
        text-align: center;
        max-width: 200px;
        margin: 50px;
    }

    .profile img {
        width: 100px;
        height: 100px;
        filter: grayscale(100%);
        border-radius: 50%; /* Macht das Bild rund */
        object-fit: cover;
        border: 3px solid #ccc; /* Optional: Rahmen */
    }

    .profile p {
        margin-top: 10px;
        font-size: 0.5em;
        color: #333;
    }

    /* Video */
    .video_screen {
        position: relative;
        width: 100%;
        padding-bottom: 56.25%; /* 16:9 Seitenverhältnis */
        height: 0;
    }

    .responsive_iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: none;
    }

    /* Responsive Anpassung */
    @media (max-width: 768px) {
        flex-direction: column;
        font-size: 1.2em; /* Kleinere Schriftgröße */

        .text_left, .text_right {
            width: 100%; /* Volle Breite auf Mobile */
            padding: 10px;
        }

        .text_left {
            padding-right: 0;
        }

        .text_right {
            padding-left: 0;
        }
    }

    @media (max-width: 480px) {
        font-size: 1em;
        line-height: 1.5;
    }
`;
