import Line from "../components/Line";
import NavBar from "../components/NavBar";
import Body from "../components/Body";
import Main from "../components/Main";
import Footer from "../components/Footer";
import Slideshow from "../components/Slideshow";
import BigPic1 from "../components/Files/tech3.png";
import BigPic2 from "../components/Files/rent.JPG";
import TextField from "../components/TextField";
import SiteHeader from "../components/SiteHeader";
import Video from "../components/Files/tro_vid.mp4";

export default function Rent() {


    return (
        <Body>
            <section>
                <SiteHeader caption={"Vermietung"}/>
                <Line/>
                <NavBar/>
                <Line/>
            </section>
            <Main>
                <Slideshow bigPic1={BigPic1} bigPic2={BigPic2} bigPic3={BigPic1}
                           captions={[
                               {text: "Messeauftritte", top: "10%", left: "5%", opacity: 0.8},
                               {text: "Persönliche Unterstützung", bottom: "15%", right: "10%", opacity: 0.8},
                               {text: "Messeauftritte", top: "10%", left: "5%", opacity: 0.8}
                           ]}
                />
                <TextField>
                    <section className="text_left">
                        <p>
                            <span style={{fontSize: "2em", display: "inline-block", marginBottom: "-0.5em"}}>W</span>ir
                            unterstützen Sie gerne mit unseren Leihgeräten bei Messeauftritten, Vorführungen,
                            Werbeständen, etc...<br/>
                            Wir helfen Ihnen, Ihr Unternehmen optimal zu repräsentieren und mit vorhandenen
                            Inhouse-Lösungen kostengünstig zum gewünschten Erfolg zu führen.<br/>
                            Fragen Sie uns an!
                        </p>
                    </section>
                    <section className="text_right">
                        <video autoPlay muted playsInline loop>
                            <source src={Video} type="video/webm"/>
                        </video>
                    </section>
                </TextField>
            </Main>
            <Footer/>
        </Body>
    )
}