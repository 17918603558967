import React from "react";
import styled from "styled-components";
import GlobalStyles from "../components/GlobalStyles";
import Logo from "./Logo";

export default function Header() {
    return (
        <>
            <GlobalStyles/>
            <Wrapper>
                {/*                <section className="header-description">
                    <img src={headerImage} className="logo" alt = "Logo"/>
                    Trobotics
                </section>*/}
                <section className="logo">
                    <Logo/>
                </section>
            </Wrapper>
        </>
    );
}
const Wrapper = styled.header`
    display: flex;
    flex-direction: row; /* Standard: Inhalte untereinander */
    justify-content: center;
    align-items: center;
    text-align: center;
    max-height: 80px;
    width: 100%;
    padding: 20px;
    //background: linear-gradient(to bottom, var(--backgrounddark), var(--backgroundlight));
    background: var(--backgroundlight);
    color: var(--foreground);
    font-family: var(--font);

    .logo {

    }

    .logo:hover {
        //transform: scaleX(-1);
    }

    .header-description {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 10px;
        font-size: 16px;
        font-weight: bolder;
        color: gray;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
    }

    .header-description h1 {
        margin: 0;
        font-weight: 100;
    }


    @media (min-width: 768px) {
        flex-direction: row;
        justify-content: start;
        text-align: left;
        //margin-left: 40px;
        .header-description {
            font-size: 70px;
        }
    }

    @media (min-width: 1024px) {
        .header-description {
            font-size: 70px;
        }

        .logo {
            padding-left: 40px;
        }
    }
`;
