import React, { useState } from "react";
import styled from "styled-components";
import Description from "./Description";
import {useNavigate} from "react-router-dom";

export default function Box ({ image, description }) {
    const [isHovered, setIsHovered] = useState(false);
    const navigate = useNavigate()

    const handleClick = () => {
        switch (description) {
            case 'Beratung' :
                navigate("/consulting");
                break;
            case 'Verkauf' :
                navigate("/sales");
                break;
            case 'Vermietung' :
                navigate("/rent");
                break;
            case 'Technical Solutions' :
                navigate("/solutions");
                break;
            default:
                navigate("/");
        }
        setTimeout(() => {
            document.documentElement.scrollTop = 0;
            document.body.scrollTop = 0;
        }, 10);
    }

    return (
        <BoxContainer
            onClick={handleClick}
            isHovered={isHovered}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <img src={image} alt="Preview" />
            <Description isHovered={isHovered}>{description}</Description>
        </BoxContainer>
    );
};


const BoxContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background: lightgray;
    border-radius: 3px;
    overflow: hidden;
    width: 100%;
    aspect-ratio: 1 / 1;
    position: relative;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: opacity 0.5s ease-in-out;
        opacity: ${({ isHovered }) => (isHovered ? "0.3" : "1")};
    }

    @media (max-width: 1600px) {
        opacity: 1; /* Immer sichtbar auf Tablets */
        width: 100%; /* 2 Spalten auf Tablets & iPads */
    }

    @media (max-width: 768px) {
            opacity: 1; /* Immer sichtbar auf Mobilgeräten */
        width: 100%; /* 2 Spalten auf Tablets */
    }

    @media (max-width: 480px) {
        opacity: 1; /* Immer sichtbar auf Mobilgeräten */
        width: 100%; /* 1 Spalte auf kleinen Smartphones */
    }
`;


