import React from "react";
import styled from "styled-components";
import {useNavigate} from "react-router-dom";

export default function Footer() {

    const navigate = useNavigate()

    const scrollUp = () => {
        setTimeout(() => {
            document.documentElement.scrollTop = 0;
            document.body.scrollTop = 0;
        }, 10);
    }
    return (
        <Wrapper>
            {/*<h2>Navigation</h2>*/}
            <ul>
                <li><a onClick={() => {
                    navigate("/imprint");
                    scrollUp();
                }}>Impressum</a></li>
                <li><a onClick={() => {
                    navigate("/dataprivacy");
                    scrollUp();
                }}>Datenschutz</a></li>
                <li><a onClick={() => {
                    navigate("/contact");
                    scrollUp();
                }}>Kontakt</a></li>
                <li><a onClick={() => {
                    navigate("/aboutus");
                    scrollUp();
                }}>Über uns</a></li>
            </ul>
        </Wrapper>
    );
}

const Wrapper = styled.footer`
    background: var(--backgroundlight); 
    width: 100%;
    height: 150px;
    padding-left: 40px;
    padding-right: 20px;
    font-family: var(--font);
    display: flex;
    flex-direction: row; /* Standardmäßig Spaltenlayout für kleine Geräte */
    align-items: flex-start;
    text-align: left;

    & > div {
        flex: 1;
        padding: 20px;
        width: 100%; /* Volle Breite für kleine Geräte */
    }

    h2 {
        font-size: 1.2rem;
        margin-bottom: 10px;
    }

    ul {
        list-style: none;
        padding: 0;
    }

    li {
        margin-bottom: 10px;
    }

    a {
        color: black;
        text-decoration: none;
        font-size: 0.8rem; /* Standard-Schriftgröße */
        cursor: pointer;
    }

    a:hover {
        color: #007bff; /* Hover-Farbe */
    }

    /* Responsives Design */
    @media (min-width: 768px) {
        flex-direction: row; /* Nebeneinander für Tablets und größere Geräte */
        text-align: left;

        & > div {
            width: auto; /* Automatische Breite auf größeren Geräten */
            padding: 40px;
        }

        a {
            font-size: 0.8rem; /* Größere Schrift für Links */
        }
    }

    @media (min-width: 1024px) {
        flex-direction: row; /* Nebeneinander für Tablets und größere Geräte */
        text-align: left;

        a {
            font-size: 0.8rem; /* Noch größere Links für Desktops */
        }

        & > div {
            padding: 50px; /* Mehr Abstand für größere Bildschirme */
        }
    }
`;
