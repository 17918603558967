import Body from "../components/Body";
import Header from "../components/Header";
import Main from "../components/Main";
import Footer from "../components/Footer";
import Content from "../components/Content";
import Pic1 from "../components/Files/epro.png"
import Pic2 from "../components/Files/hans.png"
import Pic3 from "../components/Files/Mod.jpeg"
import Pic4 from "../components/Files/pic.png"
import GifImage from "../components/Files/hns_rbt.gif"
import Line from "../components/Line";
import NavBar from "../components/NavBar";
import Box from "../components/Box";
import {useEffect, useState} from "react";
import styled from "styled-components";
import Slideshow from "../components/Slideshow";
import BigPic1 from "../components/Files/hq720.jpg";
import BigPic2 from "../components/Files/hans.png";
import BigPic3 from "../components/Files/pic.png";
import PicWartung from "../components/Files/video.mp4"
import Logo from "../components/Logo";

export default function Start() {

    const [showIntro, setShowIntro] = useState(false);

    const online = 1;

    /*
        useEffect(() => {
            setTimeout(() => {
                setShowIntro(false);
            }, 2000); // 3 Sekunden zeigen
        }, []);
    */


/*    useEffect(() => {
        if (sessionStorage.getItem("introShown")) {
            setShowIntro(false);
        } else {
            setTimeout(() => {
                setShowIntro(false);
                sessionStorage.setItem("introShown", "true");
            }, 1500); // 2 Sekunde zeigen
        }
    }, []);*/

    if (online === 0) {
        return (
            <>
                <Body>
                    <section>
                        <Header/>
                        <Line/>
                        <Line/>
                    </section>
                    <Maintenance>
                        {/*} <Slideshow bigPic1={BigPic1} bigPic2={BigPic2} bigPic3={BigPic3} button={true}/>*/}
                        <section className="video_wartung">
                            <video autoPlay muted playsInline loop>
                                <source src={PicWartung} type="video/mp4"/>
                            </video>
                        </section>
                        <h1>Hier ensteht eine neue Webpräsenz!</h1>
                    </Maintenance>
                    <Logo/>
                </Body>
            </>
        )
    }


    return (
        <>
            {showIntro === true ? (
                /*                <IntroVideoContainer>
                                    <video autoPlay muted playsInline>
                                        <source src={Video} type="video/webm"/>
                                    </video>
                                </IntroVideoContainer>*/
                <IntroImageContainer>
                    <img src={GifImage} alt="Intro Animation"/>
                </IntroImageContainer>
            ) : (
                <Body>
                    <section>
                        <Header/>
                        <Line/>
                        <NavBar/>
                        <Line/>
                    </section>

                    <Main>
                        <Slideshow bigPic1={BigPic1} bigPic2={BigPic2} bigPic3={BigPic3} button={true}/>
                        <Content>
                            <Box image={Pic1} description="Beratung"/>
                            <Box image={Pic2} description="Verkauf"/>
                            <Box image={Pic3} description="Vermietung"/>
                            <Box image={Pic4} description="Technical Solutions"/>
                        </Content>
                    </Main>
                    <Footer/>
                </Body>
            )}
        </>
    )
}

const IntroImageContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    background: rgba(255, 255, 255, 0.2); /* Halbtransparenz */
    backdrop-filter: blur(10px); /* Weiche Unschärfe für den Hintergrund */

    img {
        width: 100%;
        height: auto;
        opacity: 0.4;
    }

    animation: fadeOut 2s ease-in-out 1.8s forwards;

    @keyframes fadeOut {
        from {
            opacity: 1;
        }
        to {
            opacity: 0;
            // visibility: hidden;
        }
    }
`;

const Maintenance = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    align-items: center;
    text-align: center;
    padding: 20px;

    h1 {
        font-size: 2rem;
        color: #333;
    }

    p {
        font-size: 1.2rem;
        margin-bottom: 20px;
    }

    .video_wartung {
        width: 90%;
        max-width: 600px;
    }

    video {
        width: 100%;
        height: auto;
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    }

    @media (max-width: 768px) {
        h1 {
            font-size: 1.8rem;
        }

        p {
            font-size: 1rem;
        }

        .video_wartung {
            width: 100%;
        }
    }
`

