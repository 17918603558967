import styled from "styled-components";
import {useNavigate} from "react-router-dom";


export default function Logo () {
const navigate = useNavigate();
    const handleClick = () => {
        return navigate("/")
    }

    return (
        <LogoRow>
            <LogoTrobotics onClick={handleClick}>
                <h1><span>T</span><span>R</span><span>O</span></h1>
                <p><span>B</span><span>O</span><span>T</span><span>I</span><span>C</span><span>S</span></p>
            </LogoTrobotics>
        </LogoRow>
    )
}

const LogoRow = styled.div`

`;

const LogoTrobotics = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70px;
    //margin-bottom: 50px;
    position: relative;

    &::before, &::after {
        content: "[";
        font-size: 4rem; /* Größe der Klammern anpassen */
        font-weight: 400;
        position: absolute;
        -webkit-text-stroke: 4px var(--backgroundlight); /* Weißer Rand um die Schrift */
        top: 50%;
        transform: translateY(-50%);
    }

    &::before {
        left: -20px; /* Abstand der linken Klammer anpassen */
    }

    &::after {
        content: "]";
        right: -20px; /* Abstand der rechten Klammer anpassen */
    }

    h1 {
        width: 100%;
        margin: 0;
        font-size: 1.5rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-transform: uppercase;
    }

    p {
        width: 100%;
        margin: 0;
        font-size: 1rem;
        display: flex;
        justify-content: space-between;
        text-align: center;
    }
`;
