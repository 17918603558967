import Header from "../components/Header";
import Line from "../components/Line";
import NavBar from "../components/NavBar";
import Body from "../components/Body";
import Main from "../components/Main";
import Footer from "../components/Footer";
import styled from "styled-components";


export default function Imprint() {


    return (
        <Body>
            <section>
                <Header/>
                <Line/>
                <NavBar/>
                <Line/>
            </section>
            <Main>
                <TextFont>
                    <h3>Angaben gemäß § 5 TMG</h3>

                    <p>trobotics</p>
                    <p>Jahnplatz 3</p>
                    <p>53840 Troisdorf</p>

                    <h3>Kontakt:</h3>
                    <p>E-Mail: info@trobotics.de</p>

                    <h3>Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:</h3>
                    <p>Engels & Zeisner GbR</p>
                    <p>Jahnplatz 3</p>
                    <p>53840 Troisdorf</p>

                    <h3>Haftungsausschluss:</h3>
                    <p>Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine Haftung für die Inhalte externer
                        Links. Für den Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber
                        verantwortlich.</p>

                    <h3>Webdesign</h3>
                    <p>Thomas B. Zeisner</p>
                </TextFont>
            </Main>
            <Footer/>
        </Body>
    )
}

const TextFont = styled.section`
    font-family: var(--textfieldFont);
    `