import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import './App.css';
import Start from "./pages/Start";
import Consulting from "./pages/Consulting";
import Sales from "./pages/Sales";
import Rent from "./pages/Rent";
import TechnicalSolutions from "./pages/TechnicalSolutions";
import Imprint from "./pages/Imprint";
import DataPrivacy from "./pages/DataPrivacy";
import Contact from "./pages/Contact";
import ScrollToTop from "./components/ScrollToTop";
import AboutUs from "./pages/AboutUs";

export default function App() {

    return (

            <Router>
                <ScrollToTop /> {/* Scrollt bei jedem Seitenwechsel nach oben */}
                <Routes>
                    <Route path="/" element={<Start/>}/>
                    <Route path="/consulting" element={<Consulting/>}/>
                    <Route path="/sales" element={<Sales/>}/>
                    <Route path="/rent" element={<Rent/>}/>
                    <Route path="/solutions" element={<TechnicalSolutions/>}/>
                    <Route path="/imprint" element={<Imprint/>}/>
                    <Route path="/dataprivacy" element={<DataPrivacy/>}/>
                    <Route path="/contact" element={<Contact/>}/>
                    <Route path="/aboutus" element={<AboutUs/>}/>
                </Routes>
            </Router>

    )
}