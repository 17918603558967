
import Line from "../components/Line";
import NavBar from "../components/NavBar";
import Body from "../components/Body";
import Main from "../components/Main";
import Footer from "../components/Footer";
import styled from "styled-components";
import { Mail, Phone, MapPin } from "lucide-react";
import SiteHeader from "../components/SiteHeader";

export default function Contact() {
    return (
        <Body>
            <section>
                <SiteHeader caption={"Kontakt"}/>
                <Line/>
                <NavBar/>
                <Line/>
            </section>
            <Main>
                <ContactContainer>
                    <h1>Kontakt</h1>
                    <p>Hier finden Sie alle wichtigen Kontaktinformationen.</p>
                    <ContactInfo>
                        <ContactItem>
                            <Mail size={24} />
                            <span>info@trobotics.de</span>
                        </ContactItem>
                        <ContactItem>
                            <Phone size={24} />
                            <span>+49 (0) 2241 1697534</span>
                        </ContactItem>
                        <ContactItem>
                            <MapPin size={24} />
                            <span>Jahnplatz 3, 53840 Troisdorf</span>
                        </ContactItem>
                    </ContactInfo>
                </ContactContainer>
            </Main>
            <Footer/>
        </Body>
    );
}

const ContactContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 40px;
    background: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    margin: auto;

    h1 {
        font-size: 2rem;
        color: #333;
    }

    p {
        font-size: 1.2rem;
        color: #666;
    }
`;

const ContactInfo = styled.div`
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;
`;

const ContactItem = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 1.2rem;
    color: #333;
`;
